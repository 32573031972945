<template>
<div id="app" class="pro home page-template-default page page-id-13 wp-custom-logo wp-embed-responsive post-image-aligned-center sticky-menu-fade sticky-enabled both-sticky-menu no-sidebar nav-below-header separate-containers header-aligned-left dropdown-hover">
  <HeadNav selectedLanguage="en" page="pro" />

  <div id="page" class="site grid-container container hfeed">
    <div id="content" class="site-content">
      <div id="primary" class="content-area">
        <main id="maincontent" class="site-main">
          <article>
            <div class="inside-article">
              <div class="entry-content" itemprop="text">
                <div class="wp-block-cover alignwide has-parallax" style="min-height: 200px">
                  <span aria-hidden="true" class="wp-block-cover__background has-background-dim-80 has-background-dim wp-block-cover__gradient-background has-background-gradient" style="background: linear-gradient(180deg, #224952 0%, rgb(0, 0, 0) 99%)" />
                  <!-- <div role="img" class="wp-block-cover__image-background wp-image-622 has-parallax" style="background-position: 50% 50%; background-image: url(wp-content/uploads/2022/12/BG-2K.webp)"></div> -->
                  <div class="wp-block-cover__inner-container">
                    <!-- 首图logo -->
                    <div class="wp-block-image ic sdw">
                      <figure class="aligncenter size-full">
                        <img decoding="async" width="512" height="512" src="../assets/blog6-logo-2.webp" alt="GB WhatsApp Pro" class="wp-image-615">
                      </figure>
                    </div>

                    <h1 id="gbwhatsapp-pro" class="has-text-align-center hd">
                      GBWhatsApp Pro
                    </h1>

                    <div class="code-block code-block-4" style="margin: 8px auto; text-align: center; display: block; clear: both">
                      <p class="has-text-align-center v">
                        Version: <strong>18.20</strong> | Size: <strong>77MB</strong>
                      </p>
                    </div>

                    <p class="has-text-align-center d">
                      GB WhatsApp is a user-friendly messaging app based on the official WhatsApp, offering advanced features completely free. We recommend users to download GB WhatsApp Pro APK on our site safely. Our site also introduces all questions related to this app.
                    </p>

                    <div class="is-horizontal is-content-justification-center is-layout-flex wp-container-1 wp-block-buttons">
                      <div class="wp-block-button">
                        <router-link class="wp-block-button__link wp-element-button" :to="{name:`${selectedLanguage}-download`}">Download WhatsApp Pro
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="beesads" />

                <!-- Table of Contents -->
                <div id="toc_container" class="no_bullets">
                  <section class="table-content" v-show="!tableHide">
                    <div class="table-content-title" @click="tableHide = !tableHide">
                      Table of Contents
                      <img src="../assets/minus.svg" alt="minus">
                    </div>

                    <ul class="toc_list">
                      <li>
                        <a href="#TBC_0"><span class="toc_number toc_depth_1">1.</span> What is GB WhatsApp Pro?</a>
                      </li>
                      <li>
                        <a href="#TBC_1"><span class="toc_number toc_depth_1">2.</span> Screenshots</a>
                      </li>
                      <li>
                        <a href="#TBC_2"><span class="toc_number toc_depth_2">3.</span> Requirements to download GB WhatsApp Pro</a>
                      </li>
                      <li>
                        <a href="#TBC_3"><span class="toc_number toc_depth_3">4.</span> How to download GB WhatsApp Pro?</a>
                      </li>
                      <li>
                        <a href="#TBC_4"><span class="toc_number toc_depth_4">5.</span> Feature of GB WhatsApp Pro</a>
                      </li>
                      <li>
                        <a href="#TBC_5"><span class="toc_number toc_depth_5">6.</span> Is GB WhatsApp Pro safe?</a>
                      </li>
                      <li>
                        <a href="#TBC_6"><span class="toc_number toc_depth_6">7.</span> GB WhatsApp for PC</a>
                      </li>
                      <li>
                        <a href="#TBC_7"><span class="toc_number toc_depth_7">8.</span> GB WhatsApp for iOS</a>
                      </li>
                      <li>
                        <a href="#TBC_8"><span class="toc_number toc_depth_8">9.</span> How to update GB WhatsApp Pro</a>
                      </li>
                      <li>
                        <a href="#TBC_9"><span class="toc_number toc_depth_9">10.</span> Transfer data from WhatsApp to GB WhatsApp Pro</a>
                      </li>
                      <li>
                        <a href="#TBC_10"><span class="toc_number toc_depth_10">11.</span> Advantages and Disadvantages of GB WhatsApp Pro</a>
                      </li>
                      <li>
                        <a href="#TBC_11"><span class="toc_number toc_depth_11">12.</span> Will I get banned when using GB WhatsApp Pro?</a>
                      </li>
                      <li>
                        <a href="#TBC_12"><span class="toc_number toc_depth_12">13.</span> What should I do if my account be banned?</a>
                      </li>
                      <li>
                        <a href="#TBC_13"><span class="toc_number toc_depth_13">14.</span> Summary</a>
                      </li>
                      <li>
                        <a href="#TBC_14"><span class="toc_number toc_depth_14">15.</span> FAQ</a>
                      </li>
                    </ul>
                  </section>

                  <section class="table-content hide" v-show="tableHide">
                    <div class="table-content-title" @click="tableHide = !tableHide">
                      Table of Contents
                      <img src="../assets/plus.svg" alt="plus">
                    </div>
                  </section>

                </div>

                <h2 class="h">
                  <span id="TBC_0"> What is GB WhatsApp Pro?
                  </span>
                </h2>
                <p>
                  GB WhatsApp Pro is an advanced version of WhatsApp, offering a more customizable and feature-rich experience than the official WhatsApp app. Designed to enhance user privacy and provide practical functionalities, GB WhatsApp Pro attracts users who want additional control over their messaging, such as hiding their online status, using multiple accounts, and customizing the app's appearance. Its added features make it popular among users who want a more versatile alternative to standard WhatsApp.
                </p>

                <!-- <div
                    class="is-horizontal is-content-justification-center is-layout-flex wp-container-1 wp-block-buttons">
                    <div class="wp-block-button">
                      <a class="wp-block-button__link wp-element-button"
                        @click="gotodownload()">Download GBWhatsApp New</a>
                    </div>
                  </div> -->

                <h2 class="h">
                  <span id="TBC_1">Screenshots</span>
                </h2>

                <div class="wp-block-image">
                  <figure class="aligncenter size-full">
                    <picture>
                      <source srcset="../assets/pro-1.webp" type="image/webp">
                      <img decoding="async" loading="lazy" width="1000" height="563" src="../assets/pro-1.webp" alt="gbwhatsapp pro screenshots" class="wp-image-48">
                    </picture>
                  </figure>
                </div>

                <h2 class="h">
                  <span id="TBC_2">Requirements to Download GB WhatsApp Pro</span>
                </h2>
                <p>
                  To ensure a smooth download and installation process, your device must meet the following requirements:
                </p>
                <ul>
                  <li><strong>Operating System: </strong>Android 4.0 or higher.</li>
                  <li><strong>Permissions: </strong>Allow access to storage, contacts, and other necessary permissions for full functionality.</li>
                  <li><strong>No Root Required: </strong>GB WhatsApp Pro doesn't require root access to install.</li>
                  <li><strong>Free License: </strong>The APK is free to download and use.</li>
                  <li><strong>Trusted APK Package: </strong>Download the APK from a reliable source and allow installation from unknown sources to keep the app safe.</li>
                </ul>

                <h2 class="h">
                  <span id="TBC_3"> How to Download GB WhatsApp Pro
                  </span>
                </h2>
                <p>
                  To download and install GB WhatsApp Pro, follow these simple steps:
                </p>
                <ol>
                  <li><strong>1. Download the APK: </strong>Obtain the latest version of the GB WhatsApp Pro APK from a trusted source.</li>
                  <li><strong>2. Allow Unknown Sources: </strong>Go to Settings > Security > Enable Unknown Sources to permit the installation of apps outside the Google Play Store.</li>
                  <li><strong>3. Install the APK: </strong>Open the downloaded APK file and follow the on-screen instructions to complete the installation.</li>
                </ol>

                <h2 class="h">
                  <span id="TBC_4"> Feature of GB WhatsApp Pro
                  </span>
                </h2>
                <h2 class="center">Key Features</h2>
                <div class="is-layout-flex wp-container-8 wp-block-columns">
                  <div class="is-layout-flow wp-block-column">
                    <h3 class="h3">Ghost Mode</h3>
                    <p>
                      It enhances your privacy by letting you read messages without sending a "read" receipt. With this feature enabled, you can check messages while staying invisible to the sender, giving you more control over when and how you respond.
                    </p>

                    <h3 class="h3">Auto-Reply Messages</h3>
                    <p>
                      This feature allows you to set automated replies for specific contacts or all incoming messages. This is ideal for times when you're unavailable but still want to respond to important contacts with a preset message.
                    </p>

                    <h3 class="h3">Schedule Messages</h3>
                    <p>
                      It enables you to plan and send messages at a specific date and time automatically. Whether it's a birthday greeting, reminder, or important update, you can schedule messages in advance so they go out at just the right time.
                    </p>

                    <h3 class="h3">Anti-Delete</h3>
                    <p>
                      This feature prevents contacts from erasing messages, allowing you to view any message they originally sent, even if they attempt to remove it from the conversation.
                    </p>
                  </div>

                  <div class="is-layout-flow wp-block-column">
                    <h3 class="h3">Go to the First Message</h3>
                    <p>
                      Users can navigate back to the beginning of a chat quickly. This feature is especially helpful for lengthy conversations, making it easy to review initial messages without excessive scrolling.
                    </p>

                    <h3 class="h3">Theme Source</h3>
                    <p>
                      GB WhatsApp Pro offers access to a Theme Source where you can browse and apply various themes to personalize the app's appearance. With thousands of available themes, you can change colors, styles, and icons to suit your preferences.
                    </p>

                    <h3 class="h3">Change Fonts</h3>
                    <p>
                      The Change Fonts feature lets you adjust the style and appearance of text within the app. With several fonts to choose from, you can customize your chats for a unique and personalized look.
                    </p>

                    <h3 class="h3">Disable Forward Tag</h3>
                    <p>
                      With the Disable Forward Tag feature, you can share messages without the "Forwarded" label, allowing for a more seamless sharing experience. This helps maintain privacy and ensures forwarded messages appear as original.
                    </p>
                  </div>
                </div>

                <h2 class="center">Privacy Features</h2>
                <div class="is-layout-flex wp-container-8 wp-block-columns">
                  <div class="is-layout-flow wp-block-column">
                    <h3 class="h3">Hide Blue Ticks</h3>
                    <p>
                      With the Hide Blue Ticks option, you can prevent others from seeing when you've read their messages. This allows you to check messages at your convenience without pressure to respond immediately.
                    </p>

                    <h3 class="h3">App Lock</h3>
                    <p>
                      App Lock lets you secure your GB WhatsApp Pro with a password, fingerprint, or pattern lock, adding an extra layer of protection. This feature prevents unauthorized access to your chats, keeping your conversations safe.
                    </p>
                  </div>

                  <div class="is-layout-flow wp-block-column">
                    <h3 class="h3">Hide Online Status</h3>
                    <p>
                      Hide Online Status allows you to appear offline even when you're actively using the app. This feature is beneficial for those who want to browse or chat privately without broadcasting their online status.
                    </p>

                    <h3 class="h3">Who can call me</h3>
                    <p>
                      The Who Can Call Me feature lets you control which contacts are allowed to call you through GB WhatsApp Pro. This is particularly helpful if you want to avoid calls from specific people or limit incoming calls to only close contacts.
                    </p>
                  </div>
                </div>

                <h2 class="h">
                  <span id="TBC_5"> Is GB WhatsApp Pro Safe?
                  </span>
                </h2>
                <p>
                  GB WhatsApp Pro is a third-party modification, so it isn't officially endorsed by WhatsApp. While it offers many enhanced features, using third-party mods may come with certain risks, such as potential data privacy concerns and the chance of being banned from WhatsApp. To minimize risks, it's important to download the app from reputable sources and avoid using sensitive information within the app.
                </p>

                <h2 class="h">
                  <span id="TBC_6"> GB WhatsApp for PC
                  </span>
                </h2>
                <p>
                  Though there is no official PC version of GB WhatsApp Pro, you can access it on a computer by using an Android emulator, such as Bluestacks or Nox Player. Here's how:
                </p>
                <ol>
                  <li>1. Download and install an Android emulator on your PC.</li>
                  <li>2. Download the GB WhatsApp Pro APK file within the emulator.</li>
                  <li>3. Install GB WhatsApp Pro and log in as you would on a mobile device.</li>
                </ol>
                <p>
                  This setup allows you to use GB WhatsApp Pro on your PC seamlessly.
                </p>

                <h2 class="h">
                  <span id="TBC_7"> GB WhatsApp for iOS
                  </span>
                </h2>
                <p>
                  Currently, GB WhatsApp Pro is only available for Android. There is no official version for iOS users. While some websites may claim to offer GB WhatsApp for iOS, these are generally not legitimate and may pose security risks. iPhone users are advised to use the official WhatsApp or other reliable alternatives until an iOS-compatible version of GB WhatsApp Pro is developed.
                </p>

                <h2 class="h">
                  <span id="TBC_8"> How to Update GB WhatsApp Pro
                  </span>
                </h2>
                <p>
                  To delete GB WhatsApp Pro old version and keep the app up-to-date with the latest features and security improvements, follow these steps:
                </p>
                <ol>
                  <li>1. Check for updates on the official download site or within the app if prompted.</li>
                  <li>2. Download the latest APK version of GB WhatsApp Pro.</li>
                  <li>3. Install the APK, following the same steps as for the initial download.</li>
                  <li>4. Your data and chats should be preserved, but backing up your data before updating is always recommended.</li>
                </ol>

                <h2 class="h">
                  <span id="TBC_9"> Transfer Data from WhatsApp to GB WhatsApp Pro
                  </span>
                </h2>
                <p>
                  If you want to transfer your chat history from the official WhatsApp app to GB WhatsApp Pro, follow these steps:
                </p>
                <ol>
                  <li><strong>1. Backup WhatsApp Data: </strong>Go to Settings > Chats > Chat Backup in the official WhatsApp app, and save your backup locally.</li>
                  <li><strong>2. Install GB WhatsApp Pro: </strong>Uninstall the official WhatsApp, then download and install GB WhatsApp Pro.</li>
                  <li><strong>3. Restore Backup: </strong>Open GB WhatsApp Pro, enter your phone number, and you'll be prompted to restore your previous backup. Follow the prompts to complete the restoration process.</li>
                </ol>

                <h2 class="h">
                  <span id="TBC_10"> Advantages and Disadvantages of GB WhatsApp Pro
                  </span>
                </h2>
                <h3>
                  Advantages
                </h3>
                <ul>
                  <li><strong>Enhanced Privacy: </strong>Options to hide online status, blue ticks, and typing indicators.</li>
                  <li><strong>Increased Customization: </strong>Access to a variety of themes, font styles, and color schemes.</li>
                  <li><strong>Advanced Messaging Options: </strong>Schedule messages, auto-reply, and use of multiple accounts.</li>
                  <li><strong>Larger File Sharing Limits: </strong>Share high-resolution images, larger video files, and more media.</li>
                </ul>

                <h3>
                  Disadvantages
                </h3>
                <ul>
                  <li><strong>Security Risks: </strong>As a third-party app, it may have potential data privacy vulnerabilities.</li>
                  <li><strong>No Official Support: </strong>This app is not officially endorsed by WhatsApp, and there's a small risk of being banned.</li>
                  <li><strong>No Official iOS Version: </strong>GB WhatsApp Pro is only available for Android. Users have to use complex methods to install GB WhatsApp Pro iOS version.</li>
                </ul>

                <h2 class="h">
                  <span id="TBC_11"> Will I Get Banned When Using GB WhatsApp Pro?
                  </span>
                </h2>
                <p>
                  Yes, using GB WhatsApp Pro may lead to a ban from the official WhatsApp. WhatsApp's security team continually strengthens its detection methods to identify and ban users on unofficial versions of the app. Even with anti-ban features in the latest GB WhatsApp Pro, some users still report temporary or permanent bans. To minimize risk, it's advisable to use GB WhatsApp Pro with a secondary phone number instead of your primary number.
                </p>

                <h2 class="h">
                  <span id="TBC_12"> What Should I Do If My Account Be Banned?
                  </span>
                </h2>
                <ol>
                  <li>
                    <h4>1. Wait for the Ban to Lift: </h4>
                    <p>
                      In the case of a temporary ban, wait for the timer to complete. WhatsApp often imposes a countdown before you can resume use. Once the ban is lifted, consider switching back to the official WhatsApp to avoid future issues.
                    </p>
                  </li>
                  <li>
                    <h4>
                      2. Switch to the Official WhatsApp:
                    </h4>
                    <p>
                      Download and install the official WhatsApp app from the Google Play Store or Apple App Store. Using the official app will help prevent further bans.
                    </p>
                  </li>
                  <li>
                    <h4>
                      3. Contact WhatsApp Support:
                    </h4>
                    <p>
                      If your ban is permanent, you can try contacting WhatsApp Support. Explain your situation and request for your account to be reviewed. However, keep in mind that WhatsApp has a strict policy against modded apps and may not reinstate your account.
                    </p>
                  </li>
                </ol>

                <h2 class="h">
                  <span id="TBC_13"> Summary
                  </span>
                </h2>
                <p>
                  GB WhatsApp Pro is a popular alternative to the official WhatsApp app, providing enhanced privacy, customization options, and unique messaging features that appeal to users who want more control over their chat experience. Though it offers many benefits, GB WhatsApp Pro comes with certain risks as it is not an officially endorsed app. If you decide to use it, ensure you're aware of the security considerations and download it from reputable sources.
                </p>

                <h2 class="h">
                  <span id="TBC_14"> FAQ
                  </span>
                </h2>
                <div class="schema-faq wp-block-yoast-faq-block">
                  <div class="schema-faq-section">
                    <strong class="schema-faq-question faq-q-open">What's the difference between GBWhatsApp and GB WhatsApp Pro?</strong>
                    <p class="schema-faq-answer faq-q-open" style="display: block">
                      GBWhatsApp and GB WhatsApp Pro are very similar, sharing many features but differing in package and name. Both versions are developed by the same team.
                    </p>
                  </div>

                  <div class="schema-faq-section">
                    <strong class="schema-faq-question faq-q-open">Can I hide GB WhatsApp Pro on my phone?</strong>
                    <p class="schema-faq-answer faq-q-open" style="display: block">
                      If your phone supports hiding apps, you can use that mode. If not, you may change the icon of GB WhatsApp Pro 2025 to help disguise it.
                    </p>
                  </div>

                  <div class="schema-faq-section">
                    <strong class="schema-faq-question faq-q-open">How can I know if others are using GB WhatsApp Pro?</strong>
                    <p class="schema-faq-answer faq-q-open" style="display: block">
                      You may notice if someone can change the blue tick icon, download status videos, reply to messages offline, or use other features not available on official WhatsApp. These features indicate they may be using WhatsApp Mods.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </main>
      </div>
    </div>
  </div>

    <div id="snippet-box">
      <div class="snippet-title">
        Summary
      </div>

      <div itemscope="" itemtype="https://schema.org/SoftwareApplication">
        <div class="aio-info">
          <div class="snippet-label-img snippet-clear">
            Author Rating
          </div>
          <div class="snippet-data-img">
            <span class="star-img"><img src="@/assets/1star.png" alt="1star"><img
                src="@/assets/1star.png" alt="1star"><img src="@/assets/1star.png" alt="1star"><img
                src="@/assets/1star.png" alt="1star"><img src="@/assets/1star.png"
                alt="1star"></span>
          </div>
          <div class="snippet-clear" />
          <div class="aggregate_sec" itemprop="aggregateRating" itemscope=""
            itemtype="https://schema.org/AggregateRating">
            <div class="snippet-label-img">
              Aggregate Rating
            </div>
            <div class="snippet-data-img">
              <span itemprop="ratingValue">3.65</span> based on
              <span class="rating-count" itemprop="ratingCount">46258</span>
              votes
            </div>
          </div>
          <div class="snippet-clear" />
          <div class="snippet-label-img">
            Software Name
          </div>
          <div class="snippet-data-img">
            <span itemprop="name">GBWhatsApp APK</span>
          </div>
          <div class="snippet-clear" />
          <div class="snippet-label-img">
            Operating System
          </div>
          <div class="snippet-data-img">
            <span itemprop="operatingSystem">Android</span>
          </div>
          <div class="snippet-clear" />
          <div class="snippet-label-img">
            Software Category
          </div>
          <div class="snippet-data-img">
            <span itemprop="applicationCategory">App</span>
          </div>
          <div class="snippet-clear" />
          <div class="snippet-label-img">
            Price
          </div>
          <div class="snippet-data-img">
            <span itemprop="offers" itemscope="" itemtype="https://schema.org/Offer">
              <span itemprop="priceCurrency">$</span> <span itemprop="price"> 0</span></span>
          </div>
          <div class="snippet-clear" />
          <div class="snippet-label-img">
            Landing Page
          </div>
          <div class="snippet-data-img">
            <a itemprop="featureList" href="https://gbwhatapks.com/">https://gbwhatapks.com/</a>
          </div>
          <div class="snippet-clear" />
        </div>
      </div>
    </div>

    <div class="site-footer grid-container footer-bar-active footer-bar-align-right">
      <footer class="site-info">
        <div class="inside-site-info grid-container">
          <div class="footer-bar">
            <a href="/privacy">Privacy Policy</a>
          </div>
          <div class="copyright-bar">
            2025 © All Rights Reserved <strong><router-link :to="{name:`${selectedLanguage}-gb`}">GBWhatsApp</router-link></strong>
          </div>
        </div>
      </footer>
    </div>
    <div v-if="$global.isMobileDevice()" style="height: 52px" aria-hidden="true" class="wp-block-spacer" />

    <div v-if="$global.isMobileDevice()" id="fixed-bottom-btn" class="fix-bottom-btn"
      @click="goto('download')">
      Download GBWhatsApp New
    </div>
  </div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';
// import utlisMethods from '@/utlis/global.js';
import {
  allCommentList
} from '@/utlis/allCommentList.js';
import HeadNav from '@/components/HeadNav.vue';

export default {
  name: 'Home',
  components: {
    HeadNav,
  },
  data() {
    return {
      pageName: 'home',
      tableHide: true,
      comment: {
        name: '',
        email: '',
        content: '',
      },
      apk: null,
      clickNum: 0,
      selectedLanguage: 'en',
      lang: [{
        lang: 'en',
        val: '🇺🇸 English'
      }],
    };
  },
  computed: {
    downloadURL() {
      // var params = window.location.search;
      // return `/downloadpage${params}`;
      if (typeof window === 'undefined') {
        // Server-side rendering logic
        return '/downloadpage'; // Adjust this based on your server-side logic
      } else {
        // Client-side rendering logic
        let params = window.location.search;
        return `/downloadpage${params}`;
      }
    },
  },
  mounted() {
    this.getOfficalApk();

    // window.adsTag.renderInterstitial(54982);
  },
  methods: {
    changeLanguage(lang) {
      console.log(lang);
      // 处理语言选择变化
      // 这里可以添加其他处理逻辑，例如将语言保存到cookie或localStorage中
      this.$router.push({
        name: `${this.selectedLanguage}-gb`
      });
    },
    downloadClick() {
      this.$router.push({
        name: 'en-download'
      });
    },
    getOfficalApk(type = 1) {
      this.$server
        .getOfficalApk({
          domain: 'gbwhatapks.com',
          appName: 'GB_gbwhatapks.com',
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            if (type == 1) {
              this.apk = res.data.apk;
            }
            this.lastupdate = res.data.promotion.updateTime;
            this.votes = res.data.promotion.totalCount;
            this.rating = res.data.promotion.score;
            console.log('apk getted');
          } else {
            this.apk = process.env.VUE_APP_GBLINK;
          }
        });
    },
    gotodownload() {
      // // const params = new URLSearchParams(window.location.href.search)
      // var params = "";
      // if (typeof window !== "undefined") {
      // 	params = window.location.search;
      // }
      // // window.location.href = "/downloadpage" + params;
      // return `/downloadpage${params}`;
      // utlisMethods.download(
      // 	"https://file.cmpc.fun/upapp/1699966982678/GBWhatsApp_2.23.20.632_gw_11_14_2101_sec_sign.apk"
      // );
      window.location.href = `https://gbapp.pro?apps=gb&domain=gbwhatsapp.chat&appName=GB_gbwhatsapp.chat`;

      // this.$global.download(this.apk);
    },
    gotoblog() {
      window.location.href = '/blogs';
    },
    jump(url) {
      window.location.href = url;
    },
    goto(page) {
      this.$router.push({
        name: `${this.selectedLanguage}-${page}`
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.center {
  line-height: 50px;
  // border-bottom: 1px solid #707070;
  margin-bottom: 30px;
  font-size: 26px;
}
</style>